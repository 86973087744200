<template>
    <a-layout style="min-height: 100%;">
        <div :class="{contentPanel: true, [stylesheetName]: true}">
            <router-view></router-view>
        </div>
    </a-layout>
</template>

<script>
    export default {
        name:'GlobalLyout',
        data() {
            return {
                stylesheetName: ''
            }
        },
    }
</script>

<style lang="less">
    .table-page-search-wrapper {
        .ant-form-inline {

            .ant-form-item {
                display: flex;
                margin-bottom: 24px;
                margin-right: 0;

                .ant-form-item-control-wrapper {
                    flex: 1 1;
                    display: inline-block;
                    vertical-align: middle;
                }

                > .ant-form-item-label {
                    line-height: 32px;
                    padding-right: 8px;
                    width: auto;
                }
                .ant-form-item-control {
                    height: 32px;
                    line-height: 32px;
                }
            }
        }

        .table-page-search-submitButtons {
            display: block;
            margin-bottom: 24px;
            white-space: nowrap;
        }
    }
</style>
<style lang="less" scoped>
    // .contentPanel {
    //     height: 100%;
    // }
</style>